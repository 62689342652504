import React from 'react';
import { Field, LoginButton } from '@bedrockio/pages';

import { bem } from '@bedrockio/pages/utils';

import AppLink from 'components/AppLink';
import MailLink from 'components/MailLink';
import logo from 'assets/images/logo.svg';

import './footer.less';

@bem
export default class Footer extends React.Component {
  render() {
    return (
      <footer className={this.getBlockClass()}>
        <div className={this.getElementClass('cap')} />
        <div className={this.getElementClass('main')}>
          <svg viewBox="0 0 87 34" className={this.getElementClass('logo')}>
            <use href={`${logo}#logo`} />
          </svg>
          <div className={this.getElementClass('top')}>Get in Touch</div>
          <div className={this.getElementClass('middle')}>
            <div className={this.getElementClass('middle-addresses')}>
              <div className={this.getElementClass('middle-address')}>
                <p>Client Inquiries</p>
                <p>
                  <MailLink mail="Support@rovenutrition.com" />
                </p>
              </div>
              <div className={this.getElementClass('middle-address')}>
                <p>General Inquiries</p>
                <p>
                  <MailLink mail="Hello@rovenutrition.com" />
                </p>
              </div>
              <div className={this.getElementClass('middle-address')}>
                <p>Provider Inquiries</p>
                <p>
                  <MailLink mail="Providers@rovenutrition.com" />
                </p>
              </div>
            </div>
            <div className={this.getElementClass('middle-social')}>
              <AppLink
                to="/register"
                className={this.getElementClass('get-started')}>
                Get Started Today
              </AppLink>
            </div>
          </div>
          <div className={this.getElementClass('bottom')}>
            <div>
              <p>© 2023 Rove Nutrition LLC</p>
              <p>
                Registered Dietitian Nutritionists operating in New York and New
                Jersey
              </p>
            </div>
            <div>
              <Field name="terms" type="upload">
                Terms of Service
              </Field>
              &nbsp;&nbsp;
              <Field name="privacy" type="upload">
                Privacy Policy
              </Field>
            </div>
          </div>
        </div>
        <LoginButton />
      </footer>
    );
  }
}
