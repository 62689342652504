export default class ScrollObserver {
  constructor(el, handler) {
    this.el = el;
    this.handler = handler;
    this.setup();
  }

  setup() {
    this.observer = new IntersectionObserver(this.onIntersect, {
      // threshold,
      rootMargin: '0% 0% 0% 0%',
    });
    this.observer.observe(this.el);
  }

  destroy() {
    this.observer?.disconnect();
  }

  onIntersect = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      this.setupScroll();
    } else {
      this.destroyScroll();
    }
  };

  setupScroll() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  }

  destroyScroll() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    this.handler(this.el.getBoundingClientRect());
  };
}
