import React from 'react';
import { round, clamp } from 'lodash';
import { bem } from '@bedrockio/pages/utils';

import ScrollObserver from 'utils/ScrollObserver';
import { mapLinear } from 'utils/math';

import './color-shift.less';

@bem
export default class ColorShift extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      percent: 0,
    };
  }

  componentDidMount() {
    const { current: el } = this.ref;
    if (el) {
      this.observer = new ScrollObserver(el, this.onElementScroll);
    }
  }

  componentWillUnmount() {
    this.observer?.destroy();
  }

  getOffset() {
    let { offset } = this.props;
    if (offset) {
      offset = window.innerHeight * (offset / 100);
    }
    return offset;
  }

  onElementScroll = (rect) => {
    const offset = this.getOffset();
    const top = 0 - offset;
    const bottom = window.innerHeight - offset;
    let percent = mapLinear(rect.top, top, bottom, 0, 100);
    percent = round(percent, 2);
    percent = clamp(percent, 1, 100);

    this.setState({
      percent,
    });
  };

  getStyles() {
    const { from, to } = this.props;
    const { percent } = this.state;

    return {
      '--to': to,
      '--from': from,
      '--percent': `${percent}%`,
    };
  }

  render() {
    const { to, from, ...rest } = this.props;
    return (
      <div
        {...rest}
        ref={this.ref}
        style={this.getStyles()}
        className={this.getBlockClass()}
      />
    );
  }
}

ColorShift.defaultProps = {
  offset: 0,
};
