import React from 'react';

import { Collection } from '@bedrockio/pages';
import { bem } from '@bedrockio/pages/utils';

import IconButton from 'components/IconButton';

import './descriptions.less';

@bem
export default class AppScreenDescriptions extends React.Component {
  getStyles() {
    return {
      '--current-index': this.context.currentIndex,
    };
  }

  render() {
    return (
      <div className={this.getBlockClass()} style={this.getStyles()}>
        <Collection
          limit={this.context.length}
          name="app-screen-descriptions"
          className={this.getElementClass('items')}
          fields={{
            title: 'string',
            body: 'string',
          }}
          render={(item, data) => {
            const { title, body } = item;
            const isCurrent = data.index === this.context.currentIndex;
            return (
              <div
                className={this.getElementClass(
                  'item',
                  isCurrent ? 'current' : null,
                )}>
                <p className="bold">{title}</p>
                <div>{body}</div>
              </div>
            );
          }}
        />
        <div className={this.getElementClass('buttons')}>
          <IconButton
            name="arrow-left"
            onClick={this.context.prev}
            disabled={this.context.prevDisabled()}
          />
          <IconButton
            name="arrow-right"
            onClick={this.context.next}
            disabled={this.context.nextDisabled()}
          />
        </div>
      </div>
    );
  }
}
