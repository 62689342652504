import React from 'react';
import { Field, Meta, Spacer, Image, Icon, Collection } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Drawer from 'components/Drawer';
import Button from 'components/Button';
import Container from 'components/Container';
import Carousel from 'components/Carousel';
import AppButton from 'components/AppButton';
import Expandable from 'components/Expandable';
import ColorShift from 'components/ColorShift';
import AppScreen from 'components/AppScreen';

import hero from 'assets/images/sets/hero';

import './home.less';

export default function Home() {
  const { getElementClass } = useClass('home');
  return (
    <div>
      <Meta>
        <title>Home</title>
      </Meta>
      <Header />
      <div className={getElementClass('hero')}>
        <Image set={hero} className={getElementClass('hero-image')} />
        <div className={getElementClass('hero-main-wrapper')}>
          <div className={getElementClass('hero-main-spacer')} />
          <div className={getElementClass('hero-main')}>
            <h3 className={getElementClass('hero-header')}>
              <Field name="tagline-header" />
            </h3>
            <h4 className={getElementClass('hero-tagline')}>
              <Field name="tagline" />
            </h4>
            <Spacer />
            <AppButton primary>Book a Free Consultation</AppButton>
          </div>
          <div className={getElementClass('hero-main-spacer')}>
            <div
              className={getElementClass('hero-scroll-button')}
              onClick={() => {
                const el = document.getElementById('discover-rove');
                el.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                });
              }}>
              <Icon name="angle-down" /> Scroll to Learn More
            </div>
          </div>
        </div>
      </div>
      <div id="discover-rove" className={getElementClass('card', 'dark')}>
        <h5 className={getElementClass('card-title')}>
          <Field name="card-1-title" />
        </h5>
        <div className={getElementClass('card-body')}>
          <ColorShift from="#fcfae0" to="#0d390d" offset={-30}>
            <Field name="card-1-body" />
          </ColorShift>
        </div>
      </div>
      <div className={getElementClass('card', 'middle')}>
        <h5 className={getElementClass('card-title')}>
          <Field name="card-2-title" />
        </h5>
        <div className={getElementClass('card-body')}>
          <ColorShift from="#fcfae0" to="#0d390d" offset={-30}>
            <Field name="card-2-body" />
          </ColorShift>
        </div>
      </div>
      <div className={getElementClass('card', 'light')}>
        <h5 className={getElementClass('card-title')}>
          <Field name="card-3-title" />
        </h5>
        <div className={getElementClass('card-body')}>
          <ColorShift from="#40440b" to="#8d9245" offset={-30}>
            <Field name="card-3-body" />
          </ColorShift>
        </div>
      </div>
      <Spacer size="xl" />
      <Carousel id="how-it-works" header="How it works" style="arrows">
        <Collection
          name="works"
          fields={{
            image: 'image@420w',
            title: 'string',
            body: 'string',
          }}
          sizes={{
            image: '(max-width: 700px) 90vw, 40vw',
          }}
          render={(item, data) => {
            const { image, title, body } = item;
            const { number } = data;
            return (
              <div className={getElementClass('step')}>
                <div className={getElementClass('step-image')}>
                  {image}
                  <div className={getElementClass('step-number')}>
                    {number.toString().padStart(2, '0')}
                  </div>
                </div>
                <Spacer size="md" />
                <div className={getElementClass('step-body')}>
                  <p className="bold">{title}</p>
                  <Spacer size="xs" />
                  <p>{body}</p>
                </div>
              </div>
            );
          }}
        />
      </Carousel>
      <Spacer size="xl" />
      <Container>
        <div id="our-expertise" className={getElementClass('split')}>
          <Field
            name="split-image"
            type="image@500w"
            sizes="(max-width: 700px) 90vw, 40vw"
            className={getElementClass('split-image')}
          />
          <div className={getElementClass('split-content')}>
            <div className={getElementClass('split-title')}>Our Expertise</div>
            <div className={getElementClass('split-body')}>
              <Field name="split-body" />
            </div>
            <Spacer size="sm" />
            <Drawer
              trigger={
                <Button tight color="#7d9a81" icon="plus">
                  See Our Specialties
                </Button>
              }>
              <div className={getElementClass('split-modal')}>
                <div className={getElementClass('split-modal-header')}>
                  Conditions we help address:
                </div>
                <Spacer />
                <div className={getElementClass('split-modal-list')}>
                  <Field name="conditions-list" type="text" />
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </Container>
      <Spacer size="xl" />
      <div id="real-rove-stories" className={getElementClass('stories')}>
        <div className={getElementClass('stories-container')}>
          <Collection
            name="stories"
            fields={{
              body: 'string',
              name: 'string',
              location: 'string',
              image: 'image@100w',
            }}
            render={(story) => {
              const { body, image, location, name } = story;
              return (
                <div className={getElementClass('story')}>
                  <div className={getElementClass('story-header')}>
                    <Field name="rove-stories-title" />
                  </div>
                  <div className={getElementClass('story-body')}>{body}</div>
                  <div className={getElementClass('story-author')}>
                    <div className={getElementClass('story-author-image')}>
                      {image}
                    </div>
                    <div className={getElementClass('story-author-profile')}>
                      <div
                        className={getElementClass(
                          'story-author-profile-name',
                        )}>
                        {name}
                      </div>
                      <div
                        className={getElementClass(
                          'story-author-profile-location',
                        )}>
                        {location}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
      <Spacer size="xl" />
      <Container>
        <div id="ai-powered-app" className={getElementClass('app')}>
          <AppScreen length={4}>
            <div className={getElementClass('app-left')}>
              <div className={getElementClass('app-left-top')}>
                <p className="bold">
                  <Field name="ai-powered-title" />
                </p>
                <p className={getElementClass('app-left-top-text')}>
                  <Field name="app-description" />
                </p>
              </div>
              <div className={getElementClass('app-left-bottom')}>
                <AppScreen.Descriptions />
              </div>
            </div>
            <AppScreen.Images />
          </AppScreen>
        </div>
      </Container>
      <Spacer size="xl" />
      <Carousel id="meet-our-team" header="Our Team of Experts" style="buttons">
        <Collection
          name="experts"
          fields={{
            image: 'image@140w',
            name: 'string',
            title: 'string',
            url: 'string',
          }}
          render={(item, data) => {
            const { image, name, title } = item;
            const { url } = data;
            const firstName = data.name.split(' ')[0];
            return (
              <div className={getElementClass('expert')}>
                <div className={getElementClass('expert-image')}>{image}</div>
                <div className={getElementClass('expert-body')}>
                  <p className="bold">{name}</p>
                  <p>{title}</p>
                  <Spacer size="sm" />
                  <a href={url} className={getElementClass('expert-learn')}>
                    Meet {firstName}
                  </a>
                </div>
              </div>
            );
          }}
        />
      </Carousel>
      <Spacer size="xl" />
      <div id="faq" className={getElementClass('faq')}>
        <div className={getElementClass('faq-title')}>
          Frequently Asked Questions
        </div>
        <Spacer />

        <Collection
          name="faq"
          fields={{
            title: 'string',
            body: 'text',
          }}
          render={(item) => {
            const { title, body } = item;
            return (
              <React.Fragment>
                <Expandable className={getElementClass('faq-item')}>
                  <Expandable.Trigger className={getElementClass('faq-header')}>
                    {title}
                  </Expandable.Trigger>
                  <Spacer size="sm" />
                  <Expandable.Content>{body}</Expandable.Content>
                  <Spacer size="sm" />
                </Expandable>
                <Spacer size="sm" />
              </React.Fragment>
            );
          }}
        />
      </div>
      <Spacer size="xl" />
      <Container>
        <div className={getElementClass('get-started')}>
          <p className={getElementClass('get-started-header')}>
            Ready to get started?
          </p>
          <p className="lg cardinal balance tight">
            <Field name="get-started-body" />
          </p>
          <Spacer />
          <AppButton to="/register" primary>
            Book a free consultation
          </AppButton>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
