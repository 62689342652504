import { useClass } from '@bedrockio/pages/utils';

import { APP_URL } from 'utils/env';

import './app-button.less';

export default function AppButton(props) {
  const { to = '', primary, ...rest } = props;
  const url = `${APP_URL}${to}`;
  const { className } = useClass('app-button', primary ? 'primary' : null);
  return (
    <a
      {...rest}
      href={url}
      target="_blank"
      className={className}
      rel="external noopener noreferrer"
    />
  );
}
